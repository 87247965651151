import http from "./http";
import Service from "../Service";

/**
 * @type InvitationService
 */
export default class InvitationService extends Service {

	list(params) {
		return http.get(`admin/invitations`, {
			headers: this.getHeaders(),
            params
		});
	}

	send(invitation) {
		console.log(invitation);
		return http.post(`invitations`, invitation, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

    update_status(invitation) {
		return http.patch(`admin/invitations/${invitation.id}/status`, { status: invitation.status }, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

    notify_all(client) {
		return http.get(`admin/invitations/clients/${client.id}/notify/all`, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

}
