<template>
  <div >
      <div v-if="!isNewInvitationFormOpen" class="invitations-modal__actions-container">
        <cc-select-v2 class="status-input"
                      tracker="code"
                      :text="'name'"
                      :placeholder="'Selecione um status'"
                      @input="(s) => { set_status_filter(s) }"
                      :options="statuses"
                      v-model="status_filter">
        </cc-select-v2>
        <div class="buttons-container">
          <StandardButton style="margin-right: 20px;" :iconName="'email'" :text="'Novo Convite'" :action="showNewInvitationForm"/>
          <StandardButton :iconName="'notifications_active'" :text="'Reenviar Notificação'" :action="renotify"/>
        </div>
      </div>
      <div v-if="isNewInvitationFormOpen">
        <div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form action="" @submit.prevent="handleSubmit(save)">
              <div class="invitation-modal__form-container">
                <div style="display: flex;">
                  <div class="invitation-modal__input-wrapper">
                    <ValidationProvider rules="required" v-slot="v">
                      <span class="invitation-modal__input-title">Nome: <span style="color: red">*</span></span>
                      <input class="invitation-modal__input" 
                             type="text" 
                             v-model="invitation.receiver_name" 
                             :readonly="invitation.lock">
                      <span class="error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="invitation-modal__input-wrapper">
                    <ValidationProvider rules="required|phone" v-slot="v">
                      <span class="invitation-modal__input-title">Telefone: <span style="color: red">*</span></span>
                      <input class="invitation-modal__input"
                             v-mask="'(##) ####-#####'" 
                             type="text"
                             v-model="invitation.receiver_phone"
                             @focus="unlock_invitation"
                             :readonly="invitation.lock"
                             @blur="() => search_by_phone()">
                      <span class="error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="invitation-modal__input-wrapper">
                      <span class="invitation-modal__input-title">Empresa:</span>
                    <input class="invitation-modal__input" type="text" v-model="invitation.receiver_company_name" :readonly="invitation.lock">
                  </div>
                </div>
                <div class="invitation-modal__submit-buttons">
                  <StandardButton style="margin-right: 20px;" :iconName="'arrow_back'" :text="'Voltar'" :action="() => {isNewInvitationFormOpen = false}"/>
                  <StandardButton :iconName="'mail'" type="submit" :text="'Enviar'" :action="() => { handleSubmit(save) }" class="invitation-modal__send-btn"/>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <cc-loader style="margin: 2em;" v-show="loading"/>
      <no-data-available v-if="!loading && invitations && invitations.length == 0" :text="'Sem convites enviados'" />
      <div v-show="!loading && !invitations.length == 0" class="page-table-container">
        <div >
          <div class="page-table-header">
            <div class="page-table-header-text envelope-width">
            </div>
            <div class="page-table-header-text name-width">Nome</div>
            <div class="page-table-header-text phone-width">Telefone</div>
            <div class="page-table-header-text company-width">Empresa</div>
            <div class="page-table-header-text send-width">Enviado em</div>
            <div class="page-table-header-text select-width">Status</div>
          </div>
          <div v-for="(invitation, idx) in invitations" 
               :key="invitation.id"
               :class="{
                  canceled: invitation.status == 'CANCELED',
                  waiting: invitation.status == 'WAITING_CONFIRMATION',
                  confirmed: invitation.status == 'CONFIRMED',
                }">
            <div :class="getSpecialBackground(idx)" class="page-table-row-container">
              <div class="page-table-row">
                <div class="page-table-desc-column envelope-width">
                  <div></div>
                  <span class="material-icons-round">
                    email
                  </span>
                </div>
                <div class="page-table-desc-column name-width"><div class="page-table-header-mobile">Nome</div>{{ invitation.receiver_name }}</div>
                <div class="page-table-desc-column phone-width"><div class="page-table-header-mobile">Telefone</div><cc-whatsapp :phone="invitation.receiver_phone" /></div>
                <div class="page-table-desc-column company-width"><div class="page-table-header-mobile">Empresa</div>{{ invitation.receiver_company_name }}</div>
                <div class="page-table-desc-column send-width"><div class="page-table-header-mobile">Enviado em</div>{{ invitation.created_at | formatDate }}</div>
                <div class="page-table-desc-column select-width">
                  <div class="page-table-header-mobile">Status</div>
                  <cc-select-v2 class="color-select"
                                tracker="code"
                                :text="'name'"
                                @input="(s) => { set_status(invitation, s) }"
                                :options="statuses"
                                v-model="invitation.selected_status">
                  </cc-select-v2>
                </div>
              </div>
            </div>
          </div>
          <tr style="display: flex; justify-content: center;" v-show="total_items > 1">
            <td colspan="7" style="height: 6rem">
              <Pagination classes="orange justify-end"
                          :total="total_items"
                          :items_by_page_value="15"
                          :page="page"
                          :page_limit="9"
                          v-on:change="($event) => {
                            load($event)
                            return $event
                          }">
              </Pagination>
            </td>
          </tr>
        </div>
      </div>
  </div>
</template>

<script>
import InvitationService from '@/services/v3/invitation.service'
import ProviderService from '@/services/v3/provider.service'
import QuotationService from "@/services/QuotationService";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Pagination from "@/components/cliente/base-components/Pagination";
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import StandardInputV2 from '@/components/ui/inputs/StandardInputV2.vue'

export default {
    mixins: [ loaderMixin ],
    props: [ "client", "closeDialog" ],
    components: {
        Pagination,
        ValidationProvider,
	    	ValidationObserver,
        StandardButton,
        StandardInputV2,
    },
    data() {
        return  {
            svc: new InvitationService(),
            pro_svc: new ProviderService(),
            req_svc: new QuotationService(),
            invitations: null,
            invitation: null,
            loading: true,
            status_filter: null,
            total_items: 0,
            page: 1 ,
            statuses: [
                {
                    name: 'Aguardando Confirmação',
                    code: 'WAITING_CONFIRMATION'
                },
                {
                    name: 'Confirmado',
                    code: 'CONFIRMED'
                },
                {
                    name: 'Cancelado',
                    code: 'CANCELED'
                }
            ],
            modal: {
                title: "Convites",
                subtitle: 'Convide vendedores para sua rede',
                icon_title: 'far fa-envelope',
                style: {
                    width: "70%"
                }
            },
            isNewInvitationFormOpen: false,
            invitations: [],
            invitation: null,
        }
    },
    methods: {
        async sendInvite(invitation){
          return this.svc.send(invitation)
            .then(response => response.data).then((data) => {
                return data;
            }).catch((e) => {
                return e.response.data;
            })
        },
        async testPhone(phone){
          return this.pro_svc.find_by_phone(phone)
            .then(response => response.data).then((data) => {
                return data;
            }).catch(() => {
                return 'erro';
            })
        },
        showNewInvitationForm() {
          this.invitation = {}
          this.isNewInvitationFormOpen = true;
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        set_status_filter(s) {
            this.status_filter = s
            this.load()
        },
        set_status(invitation, s) {
            invitation.selected_status = s
            invitation.status = s.code
            this.svc.update_status({ id: invitation.id, status: s.code })
            .then(response => response.data).then((data)=>{
                invitation.saved = true
                this.$forceUpdate()
            })
        },
        translate_status(status) {
            switch(status) {
                case "CANCELED":
                    return "Cancelado"
                case "WAITING_CONFIRMATION":
                    return "Aguardando Confirmação"
                case "CONFIRMED":
                    return "Confirmado"
                default:
                    return "Não Reconhecido"
            }
        },
        save() {
            this.present_loader("Enviando convite...")

            this.search_by_phone(() => {
              this.invitation.cli_id = this.client.id
                this.svc.send(this.invitation).then(() => {
                    if(this.invitation.invited_user_id) {
                        this.req_svc.enableSeller(this.invitation.invited_user_id, this.client.id)
                        this.$emit('load_sellers')
                    }
                    this.load().then(() => this.dismiss_loader()).then(() => {
                      this.invitation = null
                      this.isNewInvitationFormOpen = false
                    })
                }).catch((err) => {
                    if(err.response && err.response.data.errors) {
                        this.$store.dispatch('notification/add', {
                            type: 'error',
                            message: err.response.data.errors && err.response.data.errors[0]
                        })
                    }
                    this.dismiss_loader()
                })
            })
        },
        cancel(invitation) {
          this.confirm_action({
				    message: "Confirma cancelamento do convite?",
			    	callback: () => {
              this.present_loader("Cancelando Convite...")
              this.svc.cancel(invitation).then(() => {
                  this.load().then(() => this.dismiss_loader())
              })
			    	}
		    	})
        },
        renotify() {
          this.confirm_action({
				    message: "Confirma reenvio de notificações dos convites pendentes?",
			    	callback: () => {
              this.present_loader("Reenviando notificações...")
              this.svc.notify_all(this.client).then(() => {
                  this.present_info("Notificações Enviadas!")
              })
			    	}
			    })
        },
        unlock_invitation() {
            this.$set(this.invitation, 'lock', false)
            this.invitation.invited_user_id = null
        },
        search_by_phone(callback = null) {
            return this.pro_svc.find_by_phone(this.invitation.receiver_phone)
            .then(response => response.data).then((data) => {
                this.invitation.receiver_name = data.usu_nome
                this.invitation.receiver_company_name = data.provider.for_nome
                this.invitation.invited_user_id = data.usu_id
                this.invitation.cli_id = this.client.id
                this.$set(this.invitation, 'lock', true)
                if(callback)callback(response)
            }).catch(() => {
                if(callback)callback(null)
            })
        },
        add() {
            this.invitation = {}
        },
        close() {
            this.$emit('close')
        },
        load(page = this.page) {
            this.loading = true
            this.page = page
            return this.svc.list({
                client_id: this.client.id, page,
                status: this.status_filter ? this.status_filter.code : null
            }).then(response => response.data).then((data)=>{
                this.invitations = data.data
                this.invitations.forEach(i => i.selected_status = this.statuses.find((s) => s.code == i.status))
                this.$forceUpdate()
                this.loading = false
                this.total_items = data.total
            })
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    /deep/.modal-body {
        padding-bottom: 10rem;
    }
    .change-status {
        display: flex;
        align-items: center;
        min-width: 15rem;
        .success-update {
            margin-left: 5px;
            visibility: hidden;
            color: $brand-success;
            &.visibile {
                visibility: 'visible';
            }
        }
    }
    .actions {
        display: flex;
        border: none;
        a {
            margin-right: 5px !important;
        }
    }
    .canceled {
        span {
            color: $brand-danger;
        }
    }
    .waiting {
        span {
            color: $yellow2;
        }
    }
    .confirmed {
        span {
            color: $ternary-color;
        }
    }
    .error {
        position: absolute;
        color: $lower-red;
    }
    i.remove-btn {
        color: $lower-red !important;
        font-size: 15px;
        cursor: pointer;
    }
    .filters {
        padding: 10px 15px;
    }

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  width: 110vw;
  min-height: 90vh;
  border-radius: 12px;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.invitations-modal__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
}
.status-input {
  width: 50vw !important;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.page-table-container {
  margin: 2em;
  overflow: auto;
  height: 65vh;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
}
.page-table-desc-column{
  padding: 0.5em 0.7em;
}
.envelope-width {
  width: 5%;
}
.name-width {
  width: 20%;
}
.phone-width {
  width: 16%;
}
.company-width {
  width: 16%;
}
.send-width {
  width: 16%;
}
.select-width {
  width: 24%;
}
.page-table-header-mobile {
  display: none;
}
.buttons-container {
  display: flex;
}
.invitations-inputs-container {
  display: flex;
}
.invitations-buttons-container {
  display: flex;
}
.attendances-page__actions-inputs {
  margin-right: 20px;
}
.invitation-modal__form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
}
.invitation-modal__input-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.invitation-modal__input {
  padding: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  width: 20vw;
}
.invitation-modal__input-wrapper {
  margin-right: 20px;
}
.invitation-modal__submit-buttons {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .page-table-header{
    display: none;
  }
  .page-table-header-mobile{
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    text-align: left;
  }
  .page-table-row{flex-direction: column;}
  .envelope-width {  width: 100%;}
  .name-width {  width: 100%;  }
  .phone-width {  width: 100%;  }
  .company-width {  width: 100%; }
  .send-width {  width: 100%; }
  .select-width {  width: 100%; }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
    font-size: 1.4em;
  }
  .color-select{width: 40% !important;}
  .page-table-header-mobile{ font-size: 1.2em; font-weight: bold;}
}

@media only screen and (max-width: 600px) {
  .modal-content-container{width: 115vw;}
}
</style>
