<template>
    <i
        class="fas fa-circle"
        :class="{
            'implanted': status == 'IMPLANTED',
            'started': status == 'STARTED',
            'waiting-first-request': status == 'WAITING_REQUEST',
            'suspended': status == 'SUSPENDED',
            'on-alert': status == 'ON_ALERT',
            'on-watch':  status == 'ON_WATCH' }"
        :title="translated_title"
    />
</template>

<script>
export default {
    props: [ "status" ],
    methods: { },
    computed: {
        translated_title() {
            switch(this.status) {
                case 'IMPLANTED':
                    return  'Implantado';
                case 'STARTED':
                    return 'Inciado'
                case 'WAITING_REQUEST':
                    return 'Aguardando Primeira Cotação'
                case 'SUSPENDED':
                    return 'Suspenso'
                case 'ON_ALERT':
                    return "Em alerta"
                case 'ON_WATCH':
                    return 'Em observação'
                default:
                    return 'Status não reconhecido'
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    i {
        &.on-watch {
            color: #5BB06D !important;
        }
        &.started {
            color: $gray6 !important;
        }
        &.waiting-first-request {
            color: $primary-color !important;
        }
        &.implanted {
            color: #e0e0e0 !important;
        }
        &.on-alert {
            color: $yellow2 !important;
        }
        &.suspended{
            color: $lower-red !important;
        }
    }
</style>
